import React from 'react';
import Navbar from './Navbar';
import HomePage from './HomePage';
import Checkin from './menu/Checkin';

const DashboardPage = () => {
  return (
    <div>
     
      <Checkin></Checkin>
    </div>
  );
};

export default DashboardPage;
