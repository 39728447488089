import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token'); // Xoá token khỏi localStorage
    navigate('/login'); // Chuyển hướng đến trang đăng nhập
  }, [navigate]);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default LogoutPage;
