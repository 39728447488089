import React, { useState } from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import Navbar from '../Navbar';
import Swal from 'sweetalert2';
const showFullContent = (content) => {
  Swal.fire({
    title: 'Nội dung chi tiết',
    html: `<div style="white-space: pre-line;">${content}</div>`,
    showCloseButton: true,
    showConfirmButton: false,
  });
};
const CheckinSearch = () => {
  const [userId, setUserId] = useState('');
  const [date, setDate] = useState('');
  const [_checkins, _setCheckins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`https://staff.ohgame.io.vn/search_checkin.php?user_id=${userId}&date=${date}`, {
        headers: {
          'token': localStorage.getItem('token') || "",
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        _setCheckins(response.data.data || []); // Ensure response.data.message is an array or fallback to an empty array
      }
    } catch (error) {
      console.error('Error fetching _checkins:', error);
      setError('Không thể tải dữ liệu, vui lòng thử lại sau.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <Container maxW="container.md" mt={8}>
        <Center mb={4}>
          <Text fontSize="2xl">Tìm kiếm checkin theo Mã nhân viên và ngày</Text>
        </Center>
        <Box>
          <Input
            placeholder="Nhập user_id"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            mb={2}
          />
          <Input
            type="date"
            placeholder="Chọn ngày"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            mb={4}
          />
          <Button colorScheme="blue" onClick={handleSearch} isLoading={loading}>
            Tìm kiếm
          </Button>
        </Box>

        <Box mt={8}>
          {_checkins.length > 0 && (
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Thời gian</Th>
                  <Th>Nội dung</Th>
                  <Th>Thiết bị</Th>
                </Tr>
              </Thead>
              <Tbody>
              {_checkins.map((checkin) => (
                <Tr key={checkin.checkin_id} bg={checkin.content==="CHECKIN-DATA-USER"?'green.100':'white'}>
                  <Td>{new Date(checkin.timestamp).toLocaleTimeString()}</Td>
                  <Td>
                    {checkin.content.length > 20 ? (
                      <>
                        {`${checkin.content.substring(0, 20)}...`}
                        <Button size="sm" ml={2} onClick={() => showFullContent(checkin.content)}>Xem thêm</Button>
                      </>
                    ) : 
                      (<Text>{(checkin.content==="CHECKIN-DATA-USER")?"Giờ ra/vào":checkin.content}</Text>)
                    }
                  </Td>
                  <Td isNumeric>{checkin.ip_address}</Td>
                 
                </Tr>
              ))}
            </Tbody>
            </Table>
          )}
          {_checkins.length === 0 && !loading && (
            <Text mt={4} fontSize="md">
              Không có dữ liệu checkin cho Mã nhân viên và ngày đã chọn.
            </Text>
          )}
          {error && (
            <Text mt={4} fontSize="md" color="red.500">
              {error}
            </Text>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default CheckinSearch;
