import React from 'react';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Flex,
    Box,
    Image,
    Text,
} from '@chakra-ui/react';
import logo from './static/Logo.png'
import { HamburgerIcon, AddIcon, ExternalLinkIcon, RepeatIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { BsClock } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { BiLogOut } from 'react-icons/bi';
const Navbar = () => {
    const navigate = useNavigate();
    return (
        <Flex justify="space-between" align="center" p="4" bg="gray.200">
            <Box>
                <Image src={logo} alt="Logo" w="104px" h="40px" />

            </Box>
            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    variant="outline"
                />
                <MenuList>
                    <MenuItem icon={<BsClock />} command="⌘C" onClick={()=>{ navigate('/checkin');}}>
                        Checkin
                    </MenuItem>
                    <MenuItem icon={<CgProfile />} command="⌘P" onClick={()=>{ navigate('/profile');}} >
                        Thông tin cá nhân
                    </MenuItem>
                    <MenuItem icon={<SearchIcon />} command="⌘S" onClick={()=>{ navigate('/search');}}>
                        Tìm Kiếm
                    </MenuItem>
                    <MenuItem icon={<BiLogOut />} command="⌘O" onClick={()=>{ navigate('/logout');}}>
                        Đăng xuất
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};

export default Navbar;
