import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Import component PrivateRoute
import LoginPage from './guest/LoginPage'; // Trang đăng nhập
import HomePage from './user/HomePage'; // Trang chủ
import DashboardPage from './user/DashboardPage'; // Trang dashboard cần bảo vệ
import ProtectedRoutes from './ProtectedRoutes';
import Checkin from './user/menu/Checkin';
import Profile from './user/menu/Profile';
import LogoutPage from './user/LogoutPage';
import CheckinSearch from './user/menu/CheckinSearch';


const App = () => {

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route element={<ProtectedRoutes/>}>
        <Route path="/" element={<Checkin />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/checkin" element={<Checkin/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/search" element={<CheckinSearch/>} />
        {/* Thêm các routes khác ở đây */}
      </Route>
    </Routes>
  );
};

export default App;
