import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Flex,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Switch,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';

const LoginPage = () => {
  const navigate = useNavigate();
  const { toggleColorMode } = useColorMode();
  const formBackground = useColorModeValue('gray.100', 'gray.700');

  const [employeeId, setEmployeeId] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://staff.ohgame.io.vn/login.php', {
        user_id: employeeId,
        password: password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        if (response.data.status==="success"){
          Swal.fire({
            title: 'Thành công!',
            text: response.data.error,
            icon: 'success',
            confirmButtonText: 'OK'
          })
          localStorage.setItem('token', response.data.data.token);
          navigate('/dashboard');
        }else{
          Swal.fire({
            title: 'Thất bại!',
            text: response.data.error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        }
       
      }
    } catch (error) {
      Swal.fire({
        title: 'Thất bại!',
        text: error,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
  };

  return (
    <Flex h="100vh" alignItems="center" justifyContent="center">
      <Flex
        flexDirection="column"
        bg={formBackground}
        p={12}
        borderRadius={8}
        boxShadow="lg"
      >
        <Heading mb={6}>DevMini Studio</Heading>
        <Input
          placeholder="Mã số nhân viên"
          type="text"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
          mb={3}
        />
        <Input
          placeholder="**********"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          mb={6}
        />
        <Button colorScheme="teal" mb={8} onClick={handleLogin}>
          Đăng nhập
        </Button>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="dark_mode" mb="0">
            Chế độ tối?
          </FormLabel>
          <Switch
            id="dark_mode"
            colorScheme="teal"
            size="lg"
            onChange={toggleColorMode}
          />
        </FormControl>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
