import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Heading,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';

const Checkin = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [checkinStatus, setCheckinStatus] = useState(false);
  const [checkins, setCheckins] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchCheckins = async () => {
      try {
        const response = await axios.get('https://staff.ohgame.io.vn/get_checkin.php', {
          headers: {
            'token': localStorage.getItem('token') || "",
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setCheckins(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching checkins:', error);
      }
    };

    fetchCheckins();
  }, [checkinStatus]);

  const formattedTime = currentTime.toLocaleTimeString();

  const handleCheckin = async () => {
    try {
      const response = await axios.post(
        'https://staff.ohgame.io.vn/checkin.php',
        { content: 'CHECKIN-DATA-USER' },
        {
          headers: {
            'token': localStorage.getItem('token') || "",
            'Content-Type': 'application/json',
          },
        }
      );
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Checkin thành công",
        showConfirmButton: false,
        timer: 1500
      });
      setCheckinStatus(!checkinStatus);
    } catch (error) {
      console.error('Error checking in:', error);
      setCheckinStatus(!checkinStatus);
    }
  };

  const handleReport = async (content) => {
    try {
      if (content === '') {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Vui lòng ghi nội dung công việc",
          showConfirmButton: false,
          timer: 1500
        });
        return
      }
      const response = await axios.post(
        'https://staff.ohgame.io.vn/checkin.php',
        { content },
        {
          headers: {
            'token': localStorage.getItem('token') || "",
            'Content-Type': 'application/json',
          },
        }
      );
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Checkin thành công",
        showConfirmButton: false,
        timer: 1500
      });
      setCheckinStatus(!checkinStatus);
    } catch (error) {
      console.error('Error reporting:', error);
      setCheckinStatus(!checkinStatus);
    }
  };

  const handleDelete = async (checkinId) => {
    try {
      const response = await axios.delete('https://staff.ohgame.io.vn/delete_checkin.php', {
        headers: {
          'token': localStorage.getItem('token') || "",
          'Content-Type': 'application/json',
        },
        data: { checkin_id: checkinId },
      });

      setCheckinStatus(!checkinStatus);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Xoá thành công",
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error deleting checkin:', error);
    }
  };

  // Function to show full content in modal or tooltip
  const showFullContent = (content) => {
    Swal.fire({
      title: 'Nội dung chi tiết',
      html: `<div style="white-space: pre-line;">${content}</div>`,
      showCloseButton: true,
      showConfirmButton: false,
    });
  };

  return (
    <div>
      <Navbar />
      <Card align="center">
        <CardHeader>
          <Heading size="md">CHẤM CÔNG</Heading>
        </CardHeader>
        <CardBody>
          <Text fontSize="3xl">{formattedTime}</Text>
        </CardBody>
        <CardFooter>
          <Button colorScheme="blue" onClick={handleCheckin}>
            Checkin
          </Button>
        </CardFooter>
        <TaskInput handleReport={handleReport} />
      </Card>

      <Center>
        <TableContainer width={'80%'}>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Thời gian</Th>
                <Th>Nội Dung</Th>
                <Th isNumeric>Thiết bị</Th>
                <Th>Hành động</Th>
              </Tr>
            </Thead>
            <Tbody>
              {checkins.map((checkin) => (
                <Tr key={checkin.checkin_id} bg={checkin.content==="CHECKIN-DATA-USER"?'green.100':'white'}>
                  <Td>{new Date(checkin.timestamp).toLocaleTimeString()}</Td>
                  <Td>
                    {checkin.content.length > 20 ? (
                      <>
                        {`${checkin.content.substring(0, 20)}...`}
                        <Button size="sm" ml={2} onClick={() => showFullContent(checkin.content)}>Xem thêm</Button>
                      </>
                    ) : 
                      (<Text>{(checkin.content==="CHECKIN-DATA-USER")?"Giờ ra/vào":checkin.content}</Text>)
                    }
                  </Td>
                  <Td isNumeric>{checkin.ip_address}</Td>
                  <Td>
                    <Button colorScheme="red" onClick={() => {
                      Swal.fire({
                        title: "Xoá sẽ không thể hoàn tác. Dữ liệu checkin của bạn sẽ bị mất?",

                        showCancelButton: true,

                        denyButtonText: `Don't save`
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          handleDelete(checkin.checkin_id)
                        } else if (result.isDenied) {

                        }
                      });
                    }}>
                      Xóa
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </div>
  );
};

export default Checkin;

function TaskInput({ handleReport }) {
  const [reportContent, setReportContent] = useState('');

  const handleClick = () => {
    handleReport(reportContent);
    setReportContent('');
  };

  return (
    <InputGroup size='md' width={"90%"}>
      <Textarea
        pr='4.5rem'
        placeholder='Báo cáo task'
        value={reportContent}
        onChange={(e) => setReportContent(e.target.value)}
      />
      <InputRightElement width='4.5rem'>
        <Button h='1.75rem' size='sm' onClick={handleClick}>
          Báo cáo
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
