import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../Navbar';
import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';

const Profile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const boxBg = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.400');
  const badgeBg = useColorModeValue('gray.50', 'gray.800');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login');
      return;
    }

    const fetchProfile = async () => {
      try {
        const response = await axios.post('https://staff.ohgame.io.vn/inform.php', {
          token: token,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.data) {
          if (response.data.error==="error"){
            localStorage.removeItem('token');
            navigate('/login');
          } else {
          
          setProfile(response.data.data);}
        }
      } catch (error) {
        console.error('Error fetching profile', error);
        localStorage.removeItem('token');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <Center py={6}>
        <Box
          maxW={'320px'}
          w={'full'}
          bg={boxBg}
          boxShadow={'2xl'}
          rounded={'lg'}
          p={6}
          textAlign={'center'}>
          <Avatar
            size={'xl'}
            src={profile.avatar || 'https://via.placeholder.com/150'}
            mb={4}
            pos={'relative'}
            _after={{
              content: '""',
              w: 4,
              h: 4,
              bg: 'green.300',
              border: '2px solid white',
              rounded: 'full',
              pos: 'absolute',
              bottom: 0,
              right: 3,
            }}
          />
          <Heading fontSize={'2xl'} fontFamily={'body'}>
            {profile.first_name+' '+profile.last_name || 'Nguyễn Cao Kỳ'}
          </Heading>
          <Text fontWeight={600} color={'gray.500'} mb={4}>
            {profile.department || 'CEO'}
          </Text>
          <Text
            textAlign={'center'}
            color={textColor}
            px={3}>
            {profile.description || ''}
          </Text>
          <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
            {/* {profile.tags && profile.tags.map((tag) => ( */}
              <Badge
                // key={tag}
                px={2}
                py={1}
                bg={badgeBg}
                fontWeight={'400'}>
                {`#${profile.job_title}`||`#Chưa phân bổ`}
              </Badge>
            {/* ))} */}
          </Stack>
          <Stack mt={8} direction={'row'} spacing={4}>
            <Button
            onClick={()=>
              {
                Swal.fire({
                  title: "Đổi mật khẩu",
                  html:
                    '<input id="swal-input1" class="swal2-input" type="password" placeholder="Mật khẩu mới">' +
                    '<input id="swal-input2" class="swal2-input" type="password" placeholder="Xác nhận mật khẩu">',
                  showCancelButton: true,
                  confirmButtonText: "Đổi mật khẩu",
                  showLoaderOnConfirm: true,
                  preConfirm: async () => {
                    const newPassword = document.getElementById("swal-input1").value;
                    const confirmPassword = document.getElementById("swal-input2").value;
                
                    if (!newPassword || !confirmPassword) {
                      Swal.showValidationMessage("Vui lòng điền đầy đủ thông tin.");
                      return;
                    }
                
                    if (newPassword !== confirmPassword) {
                      Swal.showValidationMessage("Mật khẩu mới và xác nhận mật khẩu không khớp.");
                      return;
                    }
                
                    try {
                      const response = await fetch('https://staff.ohgame.io.vn/change_password.php', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'token': localStorage.getItem('token') || "",
                        },
                        body: JSON.stringify({ new_password: newPassword })
                      });
                
                      if (!response.ok) {
                        const data = await response.json();
                        throw new Error(data.message || 'Lỗi khi đổi mật khẩu.');
                      }
                
                      return response.json();
                    } catch (error) {
                      Swal.showValidationMessage(`Lỗi: ${error}`);
                    }
                  },
                  allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire({
                      title: "Đổi mật khẩu thành công",
                      icon: "success",
                      showConfirmButton: false,
                      timer: 1500
                    });
                  }
                });
                
              }
            }
              flex={1}
              fontSize={'sm'}
              rounded={'full'}
              _focus={{
                bg: 'gray.200',
              }}>
              Đặt lại mật khẩu
            </Button>
            <Button
              flex={1}
              fontSize={'sm'}
              rounded={'full'}
              bg={'blue.400'}
              color={'white'}
              boxShadow={
                '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
              }
              _hover={{
                bg: 'blue.500',
              }}
              _focus={{
                bg: 'blue.500',
              }}>
              Cập nhật thông tin
            </Button>
          </Stack>
        </Box>
      </Center>
    </div>
  );
};

export default Profile;
